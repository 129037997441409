<template>
  <section id="pricing" class="section-price">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <div class="section-head">
            <h2>{{ $t("pricing_title") }}</h2>
            <p>{{ $t("description") }}</p>
            <span class="underline"></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="offset-0 col-12 offset-sm-2 col-sm-8 offset-md-0 col-md-6 offset-xl-0 col-xl-4">
          <div class="pricing-box">
            <div class="icon">
              <font-awesome-icon :icon="['fad', 'sack-dollar']"/>
            </div>
            <div class="pricing-details">
              <h4>{{ $t("monthly_plan") }}</h4>
              <div class="price-container">
                <span class="price">{{ $t("monthly_plan_price") }}</span>
                <span class="periodicity">{{ $t("monthly") }}</span>
              </div>
              <p>
                {{ $t("monthly_plan_description") }}
              </p>
              <ul>
                <li>{{ $t("monthly_plan_feature_1") }}</li>
                <li>{{ $t("monthly_plan_feature_2") }}</li>
                <li>{{ $t("monthly_plan_feature_3") }}</li>
                <li>{{ $t("monthly_plan_feature_4") }}</li>
                <li>{{ $t("monthly_plan_feature_5") }}</li>
              </ul>
              <router-link to="#contact" class="btn btn-primary btn-lg">{{ $t("button") }}</router-link>
            </div>
          </div>
        </div>
        <div class="offset-0 col-12 offset-sm-2 col-sm-8 offset-md-0 col-md-6 offset-xl-0 col-xl-4">
          <div class="pricing-box">
            <div class="icon">
              <font-awesome-icon :icon="['fad', 'sack-dollar']"/>
            </div>
            <div class="pricing-details">
              <h4>{{ $t("semester_plan") }}</h4>
              <div class="price-container">
                <span class="price">{{ $t("semester_plan_price") }}</span>
                <span class="periodicity">{{ $t("semiannually") }}</span>
              </div>
              <p>
                {{ $t("semester_plan_description") }}
              </p>
              <ul>
                <li>{{ $t("semester_plan_feature_1") }}</li>
                <li>{{ $t("semester_plan_feature_2") }}</li>
                <li>{{ $t("semester_plan_feature_3") }}</li>
                <li>{{ $t("semester_plan_feature_4") }}</li>
                <li>{{ $t("semester_plan_feature_5") }}</li>
              </ul>
              <router-link to="#contact" class="btn btn-primary btn-lg">{{ $t("button") }}</router-link>
            </div>
          </div>
        </div>
        <div class="offset-0 col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-xl-0 col-xl-4">
          <div class="pricing-box">
            <div class="icon">
              <font-awesome-icon :icon="['fad', 'sack-dollar']"/>
            </div>
            <div class="pricing-details">
              <h4>{{ $t("yearly_plan") }}</h4>
              <div class="price-container">
                <span class="price">{{ $t("yearly_plan_price") }}</span>
                <span class="periodicity">{{ $t("annually") }}</span>
              </div>
              <p>
                {{ $t("yearly_plan_description") }}
              </p>
              <ul>
                <li>{{ $t("yearly_plan_feature_1") }}</li>
                <li>{{ $t("yearly_plan_feature_2") }}</li>
                <li>{{ $t("yearly_plan_feature_3") }}</li>
                <li>{{ $t("yearly_plan_feature_4") }}</li>
                <li>{{ $t("yearly_plan_feature_5") }}</li>
              </ul>
              <router-link to="#contact" class="btn btn-primary btn-lg">{{ $t("button") }}</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
  export default {
    name: 'PricingSection',
    i18nOptions: {
      messages: {
        en: {
          pricing_title: 'Pricing',
          description: 'Our plan is designed to meet all levels of maturity in your business.',
          button: 'Talk to expert',
          monthly: 'monthly',
          monthly_plan: 'Monthly plan',
          monthly_plan_price: 'US$ 29.90',
          monthly_plan_description: 'All system features at an affordable price',
          monthly_plan_feature_1: 'Unlimited orders',
          monthly_plan_feature_2: 'No commissions (you don\'t pay commissions for orders placed on your site)',
          monthly_plan_feature_3: 'No installation fee',
          monthly_plan_feature_4: 'Initial registration of the menu included',
          monthly_plan_feature_5: 'Premium support by WhatsApp',
          semiannually: 'semiannually',
          semester_plan: 'Semester plan',
          semester_plan_price: 'US$ 149.90',
          semester_plan_description: 'Equivalent to US$ 24,98/month',
          semester_plan_feature_1: 'Unlimited orders',
          semester_plan_feature_2: 'No commissions (you don\'t pay commissions for orders placed on your site)',
          semester_plan_feature_3: 'No installation fee',
          semester_plan_feature_4: 'Initial registration of the menu included',
          semester_plan_feature_5: 'Premium support by WhatsApp',
          annually: 'annually',
          yearly_plan: 'Annual plan',
          yearly_plan_price: 'US$ 239.90',
          yearly_plan_description: 'Equivalent to US$ 19,99/month',
          yearly_plan_feature_1: 'Unlimited orders',
          yearly_plan_feature_2: 'No commissions (you don\'t pay commissions for orders placed on your site)',
          yearly_plan_feature_3: 'No installation fee',
          yearly_plan_feature_4: 'Initial registration of the menu included',
          yearly_plan_feature_5: 'Premium support by WhatsApp'
        },
        pt: {
          pricing_title: 'Preço',
          description: 'Todos os planos são completos com as mesmas funcionalidades, varia apenas o desconto aplicado em cada um',
          button: 'Falar com especialista',
          monthly: 'mensalmente',
          monthly_plan: 'Plano mensal',
          monthly_plan_price: 'R$ 49,90',
          monthly_plan_description: 'Todas as funcionalidades do sistema por um preço acessível',
          monthly_plan_feature_1: 'Pedidos ilimitados',
          monthly_plan_feature_2: 'Sem comissões (você não paga comissões por pedidos realizados no seu site)',
          monthly_plan_feature_3: 'Sem taxa de instalação',
          monthly_plan_feature_4: 'Cadastro inicial do cardápio incluso',
          monthly_plan_feature_5: 'Suporte premium por WhatsApp',
          semiannually: 'semestralmente',
          semester_plan: 'Plano semestral',
          semester_plan_price: 'R$ 269,90',
          semester_plan_description: 'Equivalente a R$ 44,98/mês',
          semester_plan_feature_1: 'Pedidos ilimitados',
          semester_plan_feature_2: 'Sem comissões (você não paga comissões por pedidos realizados no seu site)',
          semester_plan_feature_3: 'Sem taxa de instalação',
          semester_plan_feature_4: 'Cadastro inicial do cardápio incluso',
          semester_plan_feature_5: 'Suporte premium por WhatsApp',
          annually: 'anualmente',
          yearly_plan: 'Plano anual',
          yearly_plan_price: 'R$ 499,90',
          yearly_plan_description: 'Equivalente a R$ 41,65/mês',
          yearly_plan_feature_1: 'Pedidos ilimitados',
          yearly_plan_feature_2: 'Sem comissões (você não paga comissões por pedidos realizados no seu site)',
          yearly_plan_feature_3: 'Sem taxa de instalação',
          yearly_plan_feature_4: 'Cadastro inicial do cardápio incluso',
          yearly_plan_feature_5: 'Suporte premium por WhatsApp'
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .section-price {
    padding: 100px 0;
    background: #F9F9F9;

    .section-head {
      text-align: center;
      margin-bottom: 70px;

      h2 {
        font-size: 2rem;
        font-weight: normal;
      }

      p {
        font-size: 1.2rem;
        margin-bottom: 0;
      }

      .underline {
        display: inline-block;
        width: 160px;
        max-width: 100%;
        height: 1px;
        margin-top: 21px;
        border-top: 4px solid #51419d;
      }
    }

    .pricing-box {
      text-align: center;
      padding: 20px 20px 40px 20px;
      margin-bottom: 30px;
      background: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 3px;

      .icon {
        font-size: 46px;
        color: #51419d;
        padding: 20px 0 10px;
      }

      .pricing-details {
        h4 {
          font-size: 1.3rem;
          color: #505050;
          margin-bottom: 20px;
        }

        .price-container {
          background: #fcd05e;
          padding: 10px 0;
          margin: 0 -20px 20px;

          .price {
            display: block;
            font-size: 28px;
            font-weight: 400;
            color: #444;
            line-height: 1.2;
          }

          .periodicity {
            display: block;
            font-size: 14px;
            color: #555;
          }
        }

        p {
          margin-bottom: 30px;
          font-style: italic;
        }

        ul {
          list-style: none;
          margin: 0 0 30px;
          padding: 0;
          text-align: left;

          border-top: 1px dashed #e0e0e0;

          li {
            padding: 10px;
            border-bottom: 1px dashed #e0e0e0;
          }
        }

        .btn {
          text-transform: uppercase;
          font-size: 1rem;
        }
      }
    }
  }
</style>
