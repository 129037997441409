<template>
  <footer class="section-footer">
    <div class="container">

      <div class="row">
        <div class="col-md-6 brand">
          <span>menusoft</span>
          <p>{{ $t("menusoft_description") }}</p>
          <p class="copyright">{{ $t("copyright") }}<br>{{ $t("copyright2") }}</p>
        </div>
        <div class="col-md-6 need-help">
          <span>{{ $t("need_help") }}</span>
          <p>{{ $t("need_help_description") }}</p>
          <a :href="$t('customer_service_phone_link')" target="_blank" class="email">{{ $t("customer_service_phone") }}</a> ou <a :href="'mailto:' + $t('customer_service_email')" class="email">{{ $t("customer_service_email") }}</a>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
  export default {
    name: 'FooterSection',
    i18nOptions: {
      messages: {
        en: {
          menusoft_description: 'Menusoft is a system developed and maintained' +
            ' by Stomp Technology.',
          copyright: 'Copyright © 2019-2021 Stomp Technology. All Rights Reserved.',
          copyright2: 'All Rights Reserved.',
          need_help: 'Need Help?',
          need_help_description: 'Contact our customer support if you have any questions.',
          customer_service_phone: '+55 (81) 9 8243-1688',
          customer_service_phone_link: 'https://api.whatsapp.com/send?phone=5581982431688',
          customer_service_email: 'atendimento@stomp.net.br',
        },
        pt: {
          menusoft_description: 'Menusoft é um sistema desenvolvido e mantido pela empresa' +
            ' Stomp Tecnologia.',
          copyright: 'Copyright © Stomp Serviços em Tecnologia LTDA.',
          copyright2: 'CNPJ: 36.632.059/0001-40. Todos os direitos reservados.',
          need_help: 'Precisa de Ajuda?',
          need_help_description: 'Entre em contato com o atendimento ao cliente se tiver alguma' +
            ' dúvida.',
          customer_service_phone: '(81) 9 8243-1688',
          customer_service_phone_link: 'https://api.whatsapp.com/send?phone=5581982431688',
          customer_service_email: 'atendimento@stomp.net.br',
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .section-footer {
    padding: 75px 0 45px;
    background: #fff;

    .brand {
      span {
        display: block;
        font-size: 24px;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;

        &.copyright {
          color: #f56d45;
        }
      }
    }

    .need-help {
      span {
        display: block;
        font-size: 24px;
        /*color: #51419d;*/
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
      }

      a {
        color: #f56d45;
        margin-bottom: 10px;
      }
    }
  }


</style>
