<template>
  <div>
    <navigation-bar :router="$router" />
    <hero-section />
    <features-section />
    <how-it-works-section />
    <demo-section />
    <pricing-section />
    <contact-section />
    <footer-section />
    <floating-whatsapp />
  </div>
</template>

<script>
  import NavigationBar from '@/components/NavigationBar';
  import HeroSection from '@/components/sections/Hero2Section';
  import FeaturesSection from '@/components/sections/FeaturesSection';
  import PricingSection from '@/components/sections/PricingSection';
  import FooterSection from '@/components/sections/FooterSection';
  import ContactSection from "@/components/sections/ContactSection";
  import HowItWorksSection from "@/components/sections/HowItWorksSection";
  import FloatingWhatsapp from "@/components/sections/FloatingWhatsapp";
  import DemoSection from "@/components/sections/DemoSection";

  export default {
    name: 'home',
    components: {
      DemoSection,
      FloatingWhatsapp,
      HowItWorksSection,
      ContactSection,
      FooterSection,
      PricingSection,
      FeaturesSection,
      HeroSection,
      NavigationBar
    },
    i18nOptions: {
      messages: {
        en: {
          page_title: 'Menu online platform - Menusoft',
          meta_description: 'Build your menu online and receive orders by WhatsApp. A platform for your customer to access your menu and place orders for delivery or pickup.',
        },
        pt: {
          page_title: 'Cardápio online com pedidos via WhatsApp - Menusoft',
          meta_description: 'Monte seu cardápio online e receba pedidos pelo WhatsApp. Uma plataforma para seu cliente acessar seu cardápio e fazer pedidos de delivery ou retirada.',
        }
      }
    },
    metaInfo() {
      return {
        title: this.$t('page_title'),
        titleTemplate: null,
        htmlAttrs: {
          lang: this.$i18n.i18next.language === 'pt' ? 'pt-BR' : this.$i18n.i18next.language
        },
        link: [
          {
            rel: 'alternative',
            hreflang: 'en',
            href: 'https://www.menusoft.org'
          },
          {
            rel: 'alternative',
            hreflang: 'pt',
            href: 'https://www.menusoft.net.br'
          },
        ],
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: this.$t('meta_description')
          },
          {
            vmid: 'content-language',
            'http-equiv': 'content-language',
            content: this.$i18n.i18next.language
          }
        ]
      };
    },
    mounted() {
      document.documentElement.style.scrollBehavior = 'smooth';
    },
    beforeDestroy() {
      document.documentElement.style.scrollBehavior = '';
    },
    beforeRouteUpdate(to, from, next) {
      next();
      if (to.hash !== '') {
        // remove hash
        this.$router.replace(this.$route.path);
      }
    },
  };
</script>
