<template>
  <div>
    <div class="navigation-bar" :class="{solid: !scrollOnTop}">
      <div class="container">
        <div class="row">
          <div class="col-auto col-brand">
            <a @click.prevent="navigate('#hero')" href="#hero" class="brand">menusoft</a>
          </div>
          <div class="col">
            <ul class="menu-desktop d-md-flex navigation-links">
              <li class="spacer"></li>
              <router-link to="#features" tag="li"><a>{{ $t('features') }}</a></router-link>
              <router-link to="#how-it-works" tag="li"><a>{{ $t('how_it_works') }}</a></router-link>
              <router-link to="#demo" tag="li"><a>{{ $t('demo') }}</a></router-link>
              <router-link to="#pricing" tag="li"><a>{{ $t('pricing') }}</a></router-link>
              <router-link to="#contact" tag="li"><a>{{ $t('contact') }}</a></router-link>
              <li v-if="false">
                <span class="label-only">
                  <font-awesome-icon :icon="['fad', 'language']"/>
                </span>
                <ul class="right">
                  <li v-if="this.$i18n.i18next.language === 'en'" class="active">
                    <a>English</a>
                  </li>
                  <li v-else>
                    <a href="https://www.menusoft.org">English</a>
                  </li>
                  <li v-if="this.$i18n.i18next.language === 'pt'" class="active">
                    <a>Português</a>
                  </li>
                  <li v-else>
                    <a href="https://www.menusoft.net.br">Português</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="col-auto d-flex d-md-none">
            <button class="menu-btn d-md-none btn btn-primary"
                    v-on:click="menuMobileVisible = !menuMobileVisible">
              <font-awesome-icon :icon="['fad', menuMobileVisible ? 'times' : 'bars']"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="menuMobileVisible" class="menu-mobile-scrollable d-md-none">
      <div class="menu-mobile-container">
        <div class="menu-area">
          <div class="container">
            <ul class="menu-mobile d-md-flex navigation-links">
              <li class="spacer"></li>
              <li>
                <a @click.prevent="navigate('#features')" href="#features">{{ $t('features') }}</a>
              </li>
              <li>
                <a @click.prevent="navigate('#how-it-works')" href="#how-it-works">{{ $t('how_it_works') }}</a>
              </li>
              <li>
                <a @click.prevent="navigate('#demo')" href="#demo">{{ $t('demo') }}</a>
              </li>
              <li>
                <a @click.prevent="navigate('#pricing')" href="#pricing">{{ $t('pricing') }}</a>
              </li>
              <li>
                <a @click.prevent="navigate('#contact')" href="#contact">{{ $t('contact') }}</a>
              </li>
              <li v-if="false">
                <span class="label-only">
                  <span class="d-md-none">{{ $t('language') }}</span>
                </span>
                <ul class="right">
                  <li v-if="this.$i18n.i18next.language === 'en'" class="active">
                    <a>English</a>
                  </li>
                  <li v-else>
                    <a href="https://www.menusoft.org">English</a>
                  </li>
                  <li v-if="this.$i18n.i18next.language === 'pt'" class="active">
                    <a>Português</a>
                  </li>
                  <li v-else>
                    <a href="https://www.menusoft.net.br">Português</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <a role="button" class="menu-mobile-overlay" v-on:click="menuMobileVisible = false"></a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'navigation-bar',
    data: () => ({
      scrollOnTop: true,
      menuMobileVisible: false
    }),
    props: ['router'],
    i18nOptions: {
      messages: {
        en: {
          features: 'Features',
          how_it_works: 'How it Works',
          demo: 'Demo',
          pricing: 'Pricing',
          contact: 'Contact',
          language: 'Language',
        },
        pt: {
          features: 'Características',
          how_it_works: 'Como funciona',
          demo: 'Demo',
          pricing: 'Preço',
          contact: 'Entre em contato',
          language: 'Idioma',
        }
      }
    },
    watch: {
      menuMobileVisible(newValue) {
        this.updateMenuMobileBodyClass(newValue);
      }
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll();
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll);
      if (this.menuMobileVisible) {
        this.menuMobileVisible = false;
        this.updateMenuMobileBodyClass(false);
      }
    },
    methods: {
      onScroll() {
        if (this.scrollOnTop && document.documentElement.scrollTop !== 0) {
          this.scrollOnTop = false;
        } else if (!this.scrollOnTop && document.documentElement.scrollTop === 0) {
          this.scrollOnTop = true;
        }
      },
      updateMenuMobileBodyClass(menuMobileVisible) {
        if (menuMobileVisible && !document.documentElement.classList.contains('menu-mobile-visible')) {
          const navigationBarWidth = this.$el.querySelector('.navigation-bar').clientWidth;

          const scrollTop = document.documentElement.scrollTop;
          document.documentElement.classList.add('menu-mobile-visible');
          document.body.scrollTop = scrollTop;

          const scrollBarSize = (this.$el.querySelector('.navigation-bar').clientWidth - navigationBarWidth).toString() + 'px';
          this.$el.querySelector('.navigation-bar').style.paddingRight = scrollBarSize;
          this.$el.querySelector('.menu-area').style.paddingRight = scrollBarSize;
        } else if (!menuMobileVisible && document.documentElement.classList.contains('menu-mobile-visible')) {
          const scrollBehavior = document.documentElement.style.scrollBehavior;
          document.documentElement.style.scrollBehavior = 'unset';

          const scrollTop = document.body.scrollTop;
          document.documentElement.classList.remove('menu-mobile-visible');
          document.documentElement.scrollTop = scrollTop;

          this.$el.querySelector('.navigation-bar').style.paddingRight = '';
          this.$el.querySelector('.menu-area').style.paddingRight = '';

          document.documentElement.style.scrollBehavior = scrollBehavior;
        }
      },
      navigate(to) {
        if (this.menuMobileVisible) {
          this.menuMobileVisible = false;
          const router = this.router;
          setTimeout(function () {
            router.push(to);
          }, 0);
          return false;
        } else {
          this.router.push(to);
        }
      }
    }
  };
</script>

<style>
  @media (max-width: 767.98px) {
    html.menu-mobile-visible {
      overflow: hidden;
      scroll-behavior: unset !important;
    }

    html.menu-mobile-visible body {
      max-height: 100vh;
      overflow: auto;
    }
  }
</style>

<style lang="scss">
  @media (max-width: 767.98px) {
    .menu-mobile-visible {
      .navigation-bar {
        background: #fcd05e;

        a, .label-only {
          color: #262c40;
        }

        .menu-desktop > li {
          &:hover > a, &:hover > .label-only {
            background: #25262a;
          }
        }
      }
    }
  }

  .navigation-bar {
    background: rgba(0, 0, 0, 0.1);
    height: 50px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;

    a, .label-only {
      color: #fff;
    }

    &.solid {
      background: #fcd05e;

      a, .label-only {
        color: #262c40;
      }

      .menu-desktop > li {
        &:hover > a, &:hover > .label-only {
          background: #25262a;
        }

        ul {
          background: #25262a;

          li {
            a {
              color: #fff;
            }

            .label-only {
              color: #fff;
            }

            &:not(.active):hover a {
              color: #262c40;
            }
          }
        }
      }
    }

    .col-brand {
      display: flex;
    }

    .brand {
      line-height: 50px;
      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 22px;
      text-decoration: none;
    }

    .menu-btn {
      width: 36px;
      height: 34px;
      line-height: 34px;
      padding: 0;
      align-self: center;
      font-size: 18px;
    }
  }

  .menu-desktop {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: Poppins, sans-serif;
    font-weight: 300;

    > li {
      position: relative;

      .fa-language {
        font-size: 22px;
        vertical-align: middle;
      }

      &.spacer {
        flex-grow: 1;
      }

      > a, > .label-only {
        display: inline-block;
        line-height: 50px;
        /*color: #262c40;*/
        font-size: 14px;
        padding: 0 14px;
        text-decoration: none;
      }

      &:hover > a, &:hover > .label-only {
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
      }

      &.router-link-exact-active > a {
        font-weight: 500;
      }

      .caret {
        margin-left: 1px;
      }

      ul {
        position: absolute;
        list-style: none;
        padding: 10px;
        margin: 0;
        background: rgba(0, 0, 0, 0.3);
        display: none;
        flex-direction: column;
        border-radius: 0 0 4px 4px;

        li {
          border-radius: 18px;

          .label-only {
            display: inline-block;
            line-height: 25px;
            width: 200px;
            padding: 0 15px;
            color: #d9d6ce;
            font-size: 14px;
          }

          a {
            display: inline-block;
            width: 200px;
            line-height: 35px;
            padding: 0 15px;
            border-radius: 18px;
            text-decoration: none;
            color: #fff;
          }

          &:not(.active):hover a {
            background: #fcd05e;
            color: #262c40;
          }

          &.divider {
            border-top: 1px dashed #7a7f91;
            margin: 10px 15px;
          }
        }

        &.right {
          right: 0;
        }
      }

      &:hover ul {
        display: flex;
      }
    }
  }

  .menu-mobile-scrollable {
    position: fixed;
    z-index: 9;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .menu-mobile-container {
    position: absolute;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 50px);
  }

  .menu-area {
    background: #25262a;
    position: relative;
    top: 0;
    z-index: 2;
  }

  .menu-mobile-overlay {
    display: block;
    position: relative;
    flex-grow: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(0, 0, 0, .4);
    cursor: pointer;
  }

  .menu-mobile {
    padding: 15px 0;
    margin: 0;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    list-style: none;

    > li {
      .fa-language {
        font-size: 22px;
        vertical-align: middle;
      }

      &.spacer {
        display: none;
      }

      > .label-only {
        display: inline-block;
        line-height: 25px;
        width: 200px;
        margin-top: 10px;
        padding: 0 15px;
        color: #d9d6ce;
        font-size: 11px;
      }

      > a {
        color: #fff;
        text-decoration: none;

        display: block;
        line-height: 35px;
        padding: 0 15px;
        border-radius: 18px;
      }

      &:hover > a {
        background: #fcd05e;
        color: #262c40;
      }

      &.divider {
        border-top: 1px dashed #7a7f91;
        margin: 10px 15px;
      }

      > ul {
        background: rgba(255, 255, 255, 0.05);
        margin: 0 -10px 10px;
        padding: 10px;
        list-style: none;

        > li {
          > a {
            color: #fff;
            text-decoration: none;

            display: block;
            line-height: 35px;
            padding: 0 15px;
            border-radius: 18px;
          }

          > .label-only {
            display: inline-block;
            line-height: 25px;
            width: 200px;
            padding: 0 15px;
            color: #d9d6ce;
            font-size: 14px;
          }

          &:not(.active):hover > a {
            background: #fcd05e;
            color: #262c40;
          }

          &.divider {
            border-top: 1px dashed #7a7f91;
            margin: 10px 15px;
          }
        }
      }
    }
  }
</style>
