<template>
  <section id="hero" class="section-hero">
    <div class="container">
      <div class="row">
        <div class="hero-text col-12">
          <h1 v-html="$t('title')"></h1>
          <p>{{ $t('description') }}</p>
          <div class="row">
            <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <form class="form-inline" id="hero-form" v-on:submit.prevent="registerLead">
                <p>{{ $t('fill_form_below') }}</p>
                <div v-if="error" class="alert alert-danger alert-contact" role="alert">
                  <font-awesome-icon :icon="['fad', 'exclamation-triangle']"/> {{error}}
                </div>
                <div v-if="success" class="alert alert-success alert-contact" role="alert">
                  <font-awesome-icon :icon="['fad', 'check-circle']"/> {{success}}
                </div>
                <div class="form-group">
                  <input v-model="lead.name" type="text" class="form-control" name="name" id="name" required :placeholder="$t('name')">
                </div>
                <div class="form-group">
                  <the-mask v-model="lead.phone" type="tel" class="form-control" name="phone" id="phone" required :placeholder="$t('phone')" :masked="true" :mask="$getMask('phone')"/>
                </div>
                <template>
                  <button v-if="!loading" type="submit" class="btn btn-outline-primary">
                    <font-awesome-icon :icon="['fab', 'whatsapp']"/> {{ $t('send_btn') }}
                  </button>
                  <button v-else type="submit" class="btn btn-outline-primary" disabled>
                    {{ $t('send_btn_loading') }}
                  </button>
                </template>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12">
          <figure class="hero-img">
              <div class="img-left">
                  <img src="@/assets/images/print02.png" alt="menusoft-print-2">
              </div>
              <div class="img-center">
                  <img src="@/assets/images/print01.png" alt="menusoft-print-1">
              </div>
              <div class="img-right">
                  <img src="@/assets/images/print02.png" alt="menusoft-print-2">
              </div>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'Hero2Section',
    i18nOptions: {
      messages: {
        en: {
          title: 'Platform <br><b>online menu</b>',
          description: 'Build your menu online and receive orders by WhatsApp. An interactive platform for your customer to access your menu and place orders for delivery or pickup.',

          fill_form_below: 'Enter your name and phone number below and we will get back to you quickly!',
          name: 'Your name',
          phone: 'Your phone',
          send_btn: 'Talk to me',
          send_btn_loading: 'Sending...',
          success_message: 'Thank you for registering, we will contact you shortly.',
          error_message: 'We could not register your registration, please try again later.',
          fill_name_message: 'Please enter your name.',
          fill_phone_message: 'Please complete the form.',
        },
        pt: {
          //title: 'Plataforma de <br><b>cardápio online</b>',
          title: 'Cardápio online com <br><b>pedidos via WhatsApp</b>',
          description: 'Monte seu cardápio online e receba pedidos pelo WhatsApp. Uma plataforma interativa para seu cliente acessar seu cardápio e fazer pedidos de delivery ou retirada.',

          fill_form_below: 'Informe seu nome e telefone abaixo que entraremos em contato com você rapidamente!',
          name: 'Qual seu nome?',
          phone: 'Qual seu número (WhatsApp)?',
          send_btn: 'Falem comigo',
          send_btn_loading: 'Enviando...',
          success_message: 'Obrigado! Agora é só aguardar que entraremos em contato com você pelo whatsapp em horário comercial.',
          error_message: 'Não conseguimos registrar o seu cadastro, tente novamente mais tarde.',
          fill_name_message: 'Por favor, informe seu nome no campo abaixo.',
          fill_phone_message: 'Por favor, informe seu telefone no campo abaixo.',
        }
      }
    },
    data: () => ({
      error: null,
      success: null,
      loading: false,
      lead: {
        campaign: 'unknown',
        name: '',
        phone: ''
      }
    }),
    methods: {
      async registerLead() {
        try {
          this.success = null;
          this.error = null;

          if (!this.lead.name) {
            this.error = this.$t("fill_name_message");
            this.success = null;
            return;
          }
          if (!this.lead.phone) {
            this.error = this.$t("fill_phone_message");
            this.success = null;
            return;
          }

          if (URLSearchParams) {
            const urlParams = new URLSearchParams(window.location.search)
            const gpa = urlParams.get('gpa')
            if (gpa) {
              this.lead.campaign = `Google Ads - GPA: ${gpa}`
            } else {
              this.lead.campaign = 'unknown'
            }
          } else {
            this.lead.campaign = 'unknown'
          }

          this.lead.campaign += ' / Form: 1'

          this.loading = true
          const result = await axios.post('https://register-lead.stomp.net.br/menusoft', this.lead);
          if (result.data === 'ok') {
            gtag('event', 'conversion', {'send_to': 'AW-481244241/wdUQCO3s3u8BENHovOUB'})
            fbq('track', 'Contact')
            gtag('event', 'generate_lead')
            this.success = this.$t("success_message");
            this.error = null;
          } else {
            this.error = this.$t("error_message");
            this.success = null;
          }
        } catch (e) {
          this.error = this.$t("error_message");
          this.success = null;
        }
        this.loading = false
      }
    }
  };
</script>

<style lang="scss">
  .section-hero {
    color: #fff;
    background: #51419d;
    background: linear-gradient(0deg, #5d419d 0%, #51419d 100%);
    overflow: hidden;

    > .container > .row {
      padding: 80px 0 0;
      min-height: 95vh;
    }

    .hero-text {
      align-self: center;
      text-align: left;
      padding: 50px 15px;
      order: 0;

      h1 {
        font-size: 2rem;
        line-height: 1.1;
        font-weight: normal;
        margin-bottom: 15px;

        b {
          font-size: 1.1em;
        }
      }

      > p {
        font-size: 1.25rem;
        margin: 0 auto 30px;
      }

      @media (min-width: 576px) {
        text-align: center;

        h1 {
          font-size: 2.3rem;
          b {
            font-size: 1.3em;
          }
        }
        > p {
          max-width: 450px;
        }
      }

      @media (min-width: 768px) {
        h1 {
          font-size: 2.6rem;
        }
        > p {
          max-width: 500px;
        }
      }

      @media (min-width: 992px) {
        h1 {
          font-size: 3rem;
          margin-bottom: 20px;
        }
        > p {
          font-size: 1.5rem;
          max-width: 590px;
        }
      }

      @media (min-width: 1200px) {
        h1 {
          font-size: 3.5rem;
          margin-bottom: 25px;
        }
        > p {
          font-size: 1.75rem;
          max-width: 680px;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.1rem;
      }

      .alert {
        text-align: left;
      }
    }

    .form-inline {
      padding-top: 30px;

      .btn {
        border-color: #fff;
        color: #fff;
        text-transform: uppercase;
        font-size: 1rem;
        border-radius: 0;
        height: 42px;
        width: 100%;
        font-weight: bold;

        &:hover {
          color: #51419d;
          background-color: #fff;
          border-color: #fff;
        }
      }

      .form-group {
        width: 100%;
      }

      input {
        box-shadow: none;
        border: none;
        border-radius: 0px;
        height: 42px;
        line-height: 42px;
      }

      @media (min-width: 768px) {
        .btn {
          width: 32%;
        }

        .form-group {
            width: 33%;
            margin-right: 1%;
            display: inline-block;
            margin-bottom: 0;
            vertical-align: middle;
        }
      }
    }

    .hero-img {
      position: relative;
      margin: 20px auto -33.5%;

      img {
        width: 100%;
      }

      .img-left, .img-right {
        position: absolute;
        right: 60%;
        bottom: 0px;
        left: 0px;
        z-index: 1;
      }

      .img-right {
        right: 0px;
        left: 60%;
      }

      .img-center {
        position: relative;
        padding: 0% 27%;
        z-index: 2;
      }

      @media (min-width: 992px) {
        width: 720px;
        margin-bottom: -240px;
      }
    }
  }
</style>
