import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta'
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import {inject as injectFontAwesome} from './FontAwesome.js'
import {inject as injectIntl} from './MyIntl.js'
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
Vue.use(VueI18Next);
Vue.use(VueMeta);
injectFontAwesome(Vue);
injectIntl(Vue)

Vue.config.productionTip = false;

i18next.init({
  lng: 'en',
  resources: {}
});

const i18n = new VueI18Next(i18next);

new Vue({
  router,
  i18n: i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');
