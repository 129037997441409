import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBrowser,
  faChartNetwork,
  faWifiSlash,
  faCloud,
  faPiggyBank,
  faStore,
  faIndustryAlt,
  faBars,
  faTimes,
  faLanguage,
  faCommentLines,
  faSackDollar,
  faPrint,
  faMotorcycle, faFrenchFries, faWallet, faExclamationTriangle, faCheckCircle
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faBrowser);
library.add(faChartNetwork);
library.add(faWifiSlash);
library.add(faCloud);
library.add(faPiggyBank);
library.add(faStore);
library.add(faIndustryAlt);
library.add(faBars);
library.add(faTimes);
library.add(faLanguage);
library.add(faCommentLines);
library.add(faSackDollar);
library.add(faWhatsapp);
library.add(faPrint);
library.add(faMotorcycle);
library.add(faFrenchFries);
library.add(faWallet);
library.add(faExclamationTriangle);
library.add(faCheckCircle);

export function inject(Vue) {
  Vue.component('font-awesome-icon', FontAwesomeIcon);
}
