<template>
  <section id="how-it-works" class="section-how-it-works">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <div class="section-head"
               data-wow-duration="1.5s"
               style="visibility: visible; animation-duration: 1.5s; animation-name: fadeIn;">
            <h2>{{ $t("title") }}</h2>
            <p>{{ $t("description") }}</p>
            <span class="underline"></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <h4>{{ $t("step1") }}</h4>
            <img src="@/assets/images/como-funciona-1.jpg" :alt="$t('welcome_message_whatsapp')" :title="$t('welcome_message_whatsapp')">
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <h4>{{ $t("step2") }}</h4>
            <img src="@/assets/images/como-funciona-2.jpg" :alt="$t('choosing_product_from_online_menu')" :title="$t('choosing_product_from_online_menu')">
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <h4>{{ $t("step3") }}</h4>
            <img src="@/assets/images/como-funciona-3.jpg" :alt="$t('finish_order_online_menu')" :title="$t('finish_order_online_menu')">
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <h4>{{ $t("step4") }}</h4>
            <img src="@/assets/images/como-funciona-4.jpg" :alt="$t('receive_order_on_whatsapp')" :title="$t('receive_order_on_whatsapp')">
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="section-head">
            <p>{{ $t("cta") }}</p>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
  export default {
    name: 'HowItWorksSection',
    i18nOptions: {
      messages: {
        en: {
          title: 'How it Works',
          description: 'In a practical and fast way, your customer can place the order alone. You receive the order already completed.',
          step1: 'Customer contacts the restaurant and receives an automatic message with the platform link',
          step2: 'Customer adds the order items to the cart with the possibility of customizing the dish',
          step3: 'Upon completion, the customer reviews the order and fills in all the necessary data for delivery',
          step4: 'Restaurant receives the order already formatted on WhatsApp. Now just start the food production',
          cta: 'See how simple it is? Want a system like that for your restaurant? Talk to us and find out how easy it is!',
          welcome_message_whatsapp: 'Automatic message with a link to the online menu',
          choosing_product_from_online_menu: 'Choosing a product on the online menu',
          finish_order_online_menu: 'Finalizing the order on the online menu',
          receive_order_on_whatsapp: 'Receiving the completed order on WhatsApp'
        },
        pt: {
          title: 'Como funciona',
          description: 'De forma prática e rápida, seu cliente consegue fazer o pedido sozinho. Você recebe o pedido já finalizado.',
          step1: 'Cliente entra em contato o restaurante e recebe uma mensagem automática com o link da plataforma',
          step2: 'Cliente adiciona os itens do pedido no carrinho com a possibilidade de customização do prato',
          step3: 'Ao finalizar o cliente revisa o pedido e preenche todos os dados necessários para a entrega',
          step4: 'Restaurante recebe o pedido já formatado no WhatsApp. Agora é só começar a produção do alimento',
          cta: 'Viu como é simples? Quer um sistema assim para seu restaurante? Fala com a gente e descubra como é fácil!',
          welcome_message_whatsapp: 'Mensagem automática com um link para o cardápio online',
          choosing_product_from_online_menu: 'Escolhendo um produto no cardápio online',
          finish_order_online_menu: 'Finalizando o pedido no cardápio online',
          receive_order_on_whatsapp: 'Recebendo o pedido finalizado no WhatsApp'
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .section-how-it-works {
    background: #F9F9F9;
    padding: 100px 0 70px;

    .section-head {
      text-align: center;
      margin-bottom: 70px;

      h2 {
        font-size: 2rem;
        font-weight: normal;
      }

      p {
        font-size: 1.2rem;
        margin-bottom: 0;
      }

      .underline {
        display: inline-block;
        width: 160px;
        max-width: 100%;
        height: 1px;
        margin-top: 21px;
        border-top: 4px solid #51419d;
      }
    }

    .service {
      text-align: center;
      margin-bottom: 30px;

      img {
        max-width: 100%;
      }

      h4 {
        font-size: 1.3rem;
      }

      p {
        line-height: 1.7;
      }
    }
  }
</style>
