import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Empty from '../views/Empty.vue';
import NotFound from '../views/NotFound.vue';
import i18next from 'i18next';

Vue.use(VueRouter);

let routes = [
  {
    path: '/:lang(en|pt)?',
    component: Empty,
    children: [
      {
        path: '',
        component: Home,
      }
    ]
  },
  {
    path: '/not-found/',
    component: NotFound
  },
  {
    path: '*',
    redirect: '/not-found/',
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      };
    }
  }
});

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (window.location.hostname.match("menusoft.org$")){
    language = 'en';
  }
  if (window.location.hostname.match("menusoft.net.br$")){
    language = 'pt';
  }
  if (!language) {
    language = 'pt';
  }

  i18next.changeLanguage(language);
  next();
});

export default router;
