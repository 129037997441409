<template>
  <section id="features" class="section-features">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <div class="section-head">
            <h2>{{ $t("features") }}</h2>
            <p>{{ $t("description") }}</p>
            <span class="underline"></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fab', 'whatsapp']"/>
            <h4>{{ $t('orders_on_whatsapp') }}</h4>
            <p>{{ $t("orders_on_whatsapp_description") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fad', 'print']"/>
            <h4>{{ $t('auto_print') }}</h4>
            <p>{{ $t("auto_print_description") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fad', 'comment-lines']"/>
            <h4>{{ $t("order_automation") }}</h4>
            <p>{{ $t("order_automation_description") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fad', 'sack-dollar']"/>
            <h4>{{ $t("without_commissions") }}</h4>
            <p>{{ $t("without_commissions_description") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fad', 'browser']"/>
            <h4>{{ $t("site_app_online") }}</h4>
            <p>{{ $t("site_app_online_description") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fad', 'motorcycle']"/>
            <h4>{{ $t("delivery_fee_by_area") }}</h4>
            <p>{{ $t("delivery_fee_by_area_description") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fad', 'french-fries']"/>
            <h4>{{ $t("custom_choice") }}</h4>
            <p>{{ $t("custom_choice_description") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="service">
            <font-awesome-icon :icon="['fad', 'cloud']"/>
            <h4>{{ $t("no_download") }}</h4>
            <p>{{ $t("no_download_description") }}</p>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
  export default {
    name: 'FeaturesSection',
    i18nOptions: {
      messages: {
        en: {
          features: 'Features',
          description: 'See some of the key features of menusoft online menu platform',
          site_app_online: 'Site/App online',
          site_app_online_description: 'We provide you with a complete website for your business. Your customer will see your menu with a photo, price and description of all dishes, making it easier for your customer to choose the order.',
          order_automation: 'Automation of service',
          order_automation_description: 'Your customer places the order alone through the platform, at the end, you receive the order already formatted in WhatsApp with the customer\'s name, delivery address, chosen items, payment method and change.',
          integrated_orders: 'Integrated orders',
          integrated_orders_description: 'We integrate your delivery across all media, your' +
            ' website orders, facebook chat, whatsapp and even phone will be managed through' +
            ' the menusoft platform.',
          without_commissions: 'No commission',
          without_commissions_description: 'You only pay a fixed monthly fee for using the system. Each order placed by the Menusoft platform is free of any fee or commission.',
          works_offline: 'Works offline',
          works_offline_description: 'After the first access to the site is made, the menu can' +
            ' be accessed even if the computer or smartphone is without internet. The customer' +
            ' can make the selection of menu items and finalize the order by phone call.',
          no_download: 'No download',
          no_download_description: 'Your customer does not need to download any application to order from your foodservice, just 1 click and your menu will be on your customer\'s screen.',
          orders_on_whatsapp: 'Receive orders on WhatsApp',
          orders_on_whatsapp_description: 'Delivery orders made on your online menu arrive directly on your WhatsApp and with all the necessary information for you to prepare and send the order.',
          auto_print: 'Automatic order printing',
          auto_print_description: 'In addition to receiving orders on WhatsApp, you can configure the order to print automatically as soon as the customer finishes (thermal printers only).',
          delivery_fee_by_area: 'Delivery rate by area',
          delivery_fee_by_area_description: 'You can set a different delivery rate for each neighborhood in your delivery area. This way, your delivery customers know exactly how much they will pay and you receive the order ready.',
          custom_choice: 'Custom Options',
          custom_choice_description: 'Do you allow the customer to choose side dishes with (or without) additional value? No problems! Our delivery platform is very customizable to accommodate all items on your menu.'
        },
        pt: {
          features: 'Características',
          description: 'Veja algumas das principais funcionalidades da plataforma de cardápio online Menusoft',
          site_app_online: 'Site/App online',
          site_app_online_description: 'Fornecemos pra você um site completo do seu negócio. Seu cliente verá seu cardápio online com foto, preço e descrição de todos os pratos, facilitando a escolha para a realização do pedido.',
          order_automation: 'Automatização do atendimento',
          order_automation_description: 'Seu cliente faz o pedido sozinho no cardápio online, ao finalizar, você recebe o pedido do delivery no seu WhatsApp com nome do cliente, endereço de entrega, itens escolhidos, forma de pagamento e troco.',
          integrated_orders: 'Pedidos integrados',
          integrated_orders_description: 'Integramos seu delivery em todos os meios de' +
            ' comunicação, seus pedidos do site, chat do facebook, whatsapp e até mesmo' +
            ' telefone serão gerenciados através da plataforma menusoft.',
          without_commissions: 'Sem comissões',
          without_commissions_description: 'Você paga apenas um valor fixo mensal pela utilização do sistema. Cada pedido realizado pelo cardápio online Menusoft está livre de qualquer taxa ou comissão.',
          works_offline: 'Funciona offline',
          works_offline_description: 'Depois que o primeiro acesso ao site é feito, o cardápio' +
            ' pode ser acessado mesmo que o computador ou smartphone esteja sem internet.' +
            ' O cliente poderá fazer a seleção dos itens do cardápio e finalizar o pedido' +
            ' por ligação telefônica.',
          no_download: 'Sem download',
          no_download_description: 'Seu cliente não precisa baixar nenhum aplicativo para fazer o pedido no seu foodservice, basta 1 click e seu cardápio online estará na tela do seu cliente.',
          orders_on_whatsapp: 'Receba os pedidos no WhatsApp',
          orders_on_whatsapp_description: 'Os pedidos de delivery realizados no seu cardápio online chegam diretamente no seu WhatsApp e com todas as informações necessárias pra você preparar e enviar o pedido.',
          auto_print: 'Impressão automática de pedidos',
          auto_print_description: 'Além de receber os pedidos no WhatsApp, você pode configurar para o pedido ser impresso automaticamente assim que o cliente finalizar (apenas impressoras térmicas).',
          delivery_fee_by_area: 'Taxa de entrega por bairro',
          delivery_fee_by_area_description: 'Você pode definir uma taxa de entrega diferente para cada bairro da sua area de entrega. Desta forma, seus clientes do delivery sabem exatamente quanto vai pagar e você recebe o pedido pronto.',
          custom_choice: 'Opções Personalizados',
          custom_choice_description: 'Você permite que o cliente escolha acompanhamentos com (ou sem) valor adicional? Sem problemas! Nossa plataforma de delivery é bem customizável para comportar todos os itens do seu cardápio.'
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .section-features {
    padding: 100px 0 70px;

    .section-head {
      text-align: center;
      margin-bottom: 70px;

      h2 {
        font-size: 2rem;
        font-weight: normal;
      }

      p {
        font-size: 1.2rem;
        margin-bottom: 0;
      }

      .underline {
        display: inline-block;
        width: 160px;
        max-width: 100%;
        height: 1px;
        margin-top: 21px;
        border-top: 4px solid #51419d;
      }
    }

    .service {
      text-align: center;
      margin-bottom: 30px;

      .svg-inline--fa {
        font-size: 42px;
        color: #51419d;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 1.3rem;
      }

      p {
        line-height: 1.7;
      }
    }
  }
</style>
