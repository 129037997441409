<template>
  <section id="demo" class="section-demo">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <h2>{{ $t('demo_title') }}</h2>
          <div class="underline"></div>
          <p>{{ $t('description_1') }}</p>
          <p>{{ $t('description_2') }}</p>
          <a href="https://demo.cardapio.app.br" target="_blank" class="btn btn-primary btn-lg">
            {{ $t('btn') }}
          </a>
        </div>
        <div class="col-lg-6 col-12">
          <img class="demo-image" src="@/assets/images/demonstracao-cardapio-online.jpg" :title="$t('image_alt')" :alt="$t('image_alt')">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'DemoSection',
    i18nOptions: {
      messages: {
        en: {
          demo_title: 'Demonstration',
          description_1: 'We know that speaking well about our own system is quite obvious. So, it is only fair that you see with your own eyes.',
          description_2: 'Enter, browse and test a lot, we are sure it is exactly what you are looking for. Then just leave your contact on our website and we will be happy to serve you.',
          btn: 'Access the Demo',
          image_alt: 'Demonstration of the online menu'
        },
        pt: {
          demo_title: 'Demonstração',
          description_1: 'Sabemos que a gente falar bem do nosso próprio sistema é um tanto quanto óbvio. Então, nada mais justo que você veja com seus próprios olhos.',
          description_2: 'Entre, navegue e teste bastante, temos certeza que é exatamente o que você procura. Depois é só deixar seu contato no nosso site que teremos o maior prazer em atendê-lo.',
          btn: 'Acessar a Demonstração',
          image_alt: 'Demonstração do cardápio online'
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .section-demo {
    padding: 100px 0;
    background: #FFF;

    .underline {
      display: inline-block;
      width: 160px;
      max-width: 100%;
      height: 1px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-top: 4px solid #51419d;
    }

    h2 {
      font-size: 3rem;
      font-weight: 300;
      line-height: 1.3em;
    }

    p {
      font-size: 1.2rem;
    }

    .btn {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .demo-image {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
</style>
