<template>
  <button @click="chatOnWhatsApp" id="floating-whatsapp" class="floating-whatsapp">
    <font-awesome-icon :icon="['fab', 'whatsapp']"/>
  </button>
</template>

<script>
  export default {
    name: 'FloatingWhatsapp',
    methods: {
      chatOnWhatsApp() {
        gtag('event', 'conversion', {'send_to': 'AW-481244241/wdUQCO3s3u8BENHovOUB'})
        fbq('track', 'Contact')
        window.open('https://api.whatsapp.com/send?phone=5581982431688&text=' + encodeURIComponent('Oi, quero saber mais sobre a plataforma de delivery Menusoft'), '_blank')
      }
    }
  };
</script>

<style lang="scss" scoped>
  .floating-whatsapp {
    position: fixed;
    background: #38d366;
    border: 1px solid #30c15c;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    font-size: 46px;
  }
</style>
