import i18next from 'i18next';

const maskPhone = {
  'en': ['(###) ###-##########'],
  'pt': ['(##) # ####-######']
}

export function inject (Vue) {
  Vue.prototype.$getMask = (type) => {
    switch (type) {
      case 'phone':
        return maskPhone[i18next.language]
    }
  }
}
