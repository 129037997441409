<template>
  <section id="contact" class="section-contact">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <div class="section-head">
            <h2>{{ $t("title") }}</h2>
            <p>{{ $t("description") }}</p>
            <span class="underline"></span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-12 col-lg-8 offset-lg-2">
          <div v-if="error" class="alert alert-danger alert-contact" role="alert">
            {{error}}
          </div>
          <div v-if="success" class="alert alert-success alert-contact" role="alert">
            {{success}}
          </div>
        </div>
      </div>

      <form v-on:submit.prevent="registerLead">
        <div class="form-row">
          <div class="form-group col-12 col-md-6 col-lg-4 offset-lg-2">
            <label for="name" class="form-label">{{ $t("name") }}</label>
            <div class="input-group">
              <input id="name" autocomplete="name" type="text" class="form-control"
                     v-model="lead.name">
            </div>
          </div>
          <div class="form-group col-12 col-md-6 col-lg-4">
            <label for="phone" class="form-label">{{ $t("phone") }}</label>
            <div class="input-group">
              <the-mask
                id="phone"
                v-model="lead.phone"
                autocomplete="tel"
                type="tel"
                class="form-control"
                placeholder="(99) 9 9999-9999"
                :masked="true"
                :mask="$getMask('phone')"
              />
            </div>
          </div>
        </div>
        <div class="form-row justify-content-end">
          <div class="col-auto">
            <button class="btn btn-primary" :disabled="loading">{{ !loading ? $t("send_btn") : $t("send_btn_loading") }}</button>
          </div>
          <div class="offset-lg-2"></div>
        </div>
      </form>

    </div>
  </section>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'ContactSection',
    i18nOptions: {
      messages: {
        en: {
          title: 'We contact you',
          description: 'Inform your contact below that we will contact you by whatsapp shortly (during business hours)',
          name: 'Name',
          phone: 'Phone',
          email: 'E-mail',
          send_btn: 'Send',
          send_btn_loading: 'Sending...',
          success_message: 'Thank you for registering, we will contact you shortly.',
          error_message: 'We could not register your registration, please try again later.',
          fill_name_message: 'Please enter your name.',
          fill_phone_message: 'Please complete the form.',
        },
        pt: {
          title: 'Entramos em contato com você',
          description: 'Informe seu contato abaixo que entraremos em contato pelo whatsapp em breve (em horário comercial)',
          name: 'Seu nome',
          phone: 'Seu WhatsApp (com DDD)',
          send_btn: 'Solicitar Contato',
          send_btn_loading: 'Enviando...',
          success_message: 'Obrigado! Agora é só aguardar que entraremos em contato em breve.',
          error_message: 'Não conseguimos registrar o seu cadastro, tente novamente mais tarde.',
          fill_name_message: 'Por favor, informe seu nome.',
          fill_phone_message: 'Por favor, informe seu telefone.',
        }
      }
    },
    data: () => ({
      error: null,
      success: null,
      loading: false,
      lead: {
        campaign: 'unknown',
        name: '',
        phone: ''
      }
    }),
    methods: {
      async registerLead() {
        try {
          if (!this.lead.name) {
            this.error = this.$t("fill_name_message");
            this.success = null;
            return;
          }
          if (!this.lead.phone) {
            this.error = this.$t("fill_phone_message");
            this.success = null;
            return;
          }

          if (URLSearchParams) {
            const urlParams = new URLSearchParams(window.location.search)
            const gpa = urlParams.get('gpa')
            if (gpa) {
              this.lead.campaign = `Google Ads - GPA: ${gpa}`
            } else {
              this.lead.campaign = 'unknown'
            }
          } else {
            this.lead.campaign = 'unknown'
          }

          this.lead.campaign += ' / Form: 2'

          this.loading = true
          const result = await axios.post('https://register-lead.stomp.net.br/menusoft', this.lead);
          if (result.data === 'ok') {
            gtag('event', 'conversion', {'send_to': 'AW-481244241/wdUQCO3s3u8BENHovOUB'})
            fbq('track', 'Contact')
            gtag('event', 'generate_lead')
            this.success = this.$t("success_message");
            this.error = null;
          } else {
            this.error = this.$t("error_message");
            this.success = null;
          }
        } catch (e) {
          this.error = this.$t("error_message");
          this.success = null;
        }
        this.loading = false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .section-contact {
    padding: 100px 0;
    background: #262c40;
    color: #fff;

    .section-head {
      text-align: center;
      margin-bottom: 70px;

      h2 {
        font-size: 2rem;
        font-weight: normal;
      }

      p {
        font-size: 1.2rem;
        margin-bottom: 0;
      }

      .underline {
        display: inline-block;
        width: 160px;
        max-width: 100%;
        height: 1px;
        margin-top: 21px;
        border-top: 4px solid #fcd05e;
      }
    }

    .alert-contact-x {
      display: table;
      margin: 0 auto 1rem;
    }
  }
</style>
