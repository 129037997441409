<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: '',
      // all titles will be injected into this template
      titleTemplate: function (title) {
        if (title) {
          return `${title} - Menusoft`;
        } else {
          return '';
        }
      }
    }
  };
</script>
<style lang="scss">
  @import "assets/styles/index";
</style>
